import React, { useReducer } from "react";
import UsuarioReducer from "./UsuarioReducer";
import UsuarioContext from "./UsuarioContext";
import axios from "axios";
import { GET_USUARIO, GET_EVENTOS,SET_EVENTO,GET_EXPEDIENTE,GET_PACIENTES,GET_SESIONES,GET_PACIENTE,SET_USUARIO } from "../types";

const UsuarioState = (props) => {
  const initialState = {
    usuario: [],
  eventos:[],
  expediente:[],
  pacientes:[],
  paciente:[],
  sesiones:[],
  resp:'',
  };
  const [state, dispatch] = useReducer(UsuarioReducer, initialState);

  const setUsuario =  (datos) => {

    dispatch({
      type: SET_USUARIO,
      payload: datos,
    });
  }

  const getUsuario = async (correo) => {
    try {
      // const res = await axios.post(
      //   "http://localhost/api/index.php/evento/lista?terapeuta="+ user
      // );

      const data = [
        { id: "1", nombre: "Ramses Rivero Fragoso", rol:3},
      ];

      dispatch({
        type: GET_USUARIO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPacientes= async (usuario,rol) => {
    try {

      const res = await axios.get(
        "http://localhost/api/index.php/evento/pacientes?usuario="+usuario+"&rol="+rol);

      const data = res.data;

      dispatch({
        type: GET_PACIENTES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getPaciente= async (usuario,rol,id) => {
    try {

      const res = await axios.get(
        "http://localhost/api/index.php/evento/paciente?usuario="+usuario+"&rol="+rol+"&id=" + id);

      const data = res.data;

      dispatch({
        type: GET_PACIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };


  const getEventos = async (usuario,rol) => {
    try {
      const res = await axios.get(
        "http://localhost/api/index.php/evento/lista?terapeuta=" + usuario  + "&rol="+rol      );

      const data = res.data;

      dispatch({
        type: GET_EVENTOS,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setEvento = async (datos) => {
    try {
      const res = await axios.post(
        "http://localhost/api/index.php/evento/add",
        datos
      );

      const data = res.data;

      dispatch({
        type: SET_EVENTO,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getExpediente = async (paciente) => {
    try {
      const res = await axios.get(
        "http://localhost/api/index.php/evento/expediente?paciente="+
        paciente
      );

      const data = res.data;

      dispatch({
        type: GET_EXPEDIENTE,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getSesiones = async (paciente) => {
    try {
      const res = await axios.get(
        "http://localhost/api/index.php/evento/sesiones?paciente="+
        paciente
      );

      const data = res.data;

      dispatch({
        type: GET_SESIONES,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <UsuarioContext.Provider
      value={{
        usuario: state.usuario,
        eventos: state.eventos,
        respuesta: state.res,
        expediente: state.expediente,
        pacientes: state.pacientes,
        resp: state.resp,
        sesiones: state.sesiones,
        paciente: state.paciente,
        getUsuario,
        getEventos,
        setEvento,
        getExpediente,
        getPacientes,
        getSesiones,
        getPaciente,
        setUsuario

      }}
    >
      {props.children}
    </UsuarioContext.Provider>
  );
};

export default UsuarioState;
