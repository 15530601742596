import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Menu from "../componentes/menu";
import Calendario from "../pages/calendario";
import Pacientes from "../pages/pacientes";
import Expediente from "../pages/expediente";

export default function AppRouter() {
  return (
    <div>
      <Router>
      <Menu />

      <Routes>
        <Route exact path="/" element={<Calendario />} />
        <Route exact path="/pacientes" element={<Pacientes />} />
        <Route exact path="/expediente/:id" element={<Expediente />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
</Router>
    </div>
  );
}
