import { useEffect, useContext, useState } from "react";

import UsuarioContext from "../context/usuarios/UsuarioContext.js";

import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddEvento from "../componentes/addevento.jsx";
import CheckEvento from "../componentes/checkevento.jsx";

dayjs.locale("es");

const localizer = dayjsLocalizer(dayjs);

export default function Calendario() {
  const { eventos, usuario, getEventos } = useContext(UsuarioContext);
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [addeve, setaddeve] = useState(false);
  const [check, setcheck] = useState(false);
  const [paciente, setpaciente] = useState(0);
  const [terapia, setterapia] = useState("");
  const [aparato, setaparato] = useState("");

  useEffect(() => {
    getEventos(usuario[0].id, usuario[0].rol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let agenda = [];

  if (eventos.length > 0) {
    eventos.forEach(function (element) {
      agenda.push({
        title: element.paciente,
        start: dayjs(element.start).toDate(),
        end: dayjs(element.end).toDate(),
        paciente: element.paciente,
        terapia: element.terapia,
        aparato: element.aparato,
      });
    });
  }

  const handleSelectEvent = (e) => {
    setpaciente(e.paciente);
    setterapia(e.terapia);
    setaparato(e.aparato);
    setstart(e.start);
    setend(e.end);
    setcheck(true);
    setaddeve(false);
  };

  const handleSelectSlot = (e) => {
    setpaciente(0);
    setstart(e.start);
    setend(e.end);
    setcheck(false);
    setaddeve(true);
  };

  if (usuario[0].rol !== 3) {
    return (
      <div>
        <div className="d-flex justify-content-center pt-5 mt-5">
          <Calendar
            localizer={localizer}
            events={agenda}
            startAccessor="start"
            endAccessor="end"
            views={["day", "week", "month"]}
            min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
            max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
            defaultView="day"
            step={30}
            style={{ height: "65vh", width: "80vw" }}
            selectable
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            // onSelecting={handleSelectEvent}
            messages={{
              next: "Siguiente",
              previous: "Anterior",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="d-flex justify-content-center pt-5 mt-5">
          <Calendar
            localizer={localizer}
            events={agenda}
            startAccessor="start"
            endAccessor="end"
            views={["day", "week", "month"]}
            min={dayjs().set("hour", 7).set("minute", 0).set("second", 0)}
            max={dayjs().set("hour", 19).set("minute", 0).set("second", 0)}
            defaultView="day"
            step={30}
            style={{ height: "65vh", width: "80vw" }}
            selectable
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            // onSelecting={handleSelectEvent}
            messages={{
              next: "Siguiente",
              previous: "Anterior",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día",
            }}
          />
        </div>
        {check ? (
          <CheckEvento
            paciente={paciente}
            terapia={terapia}
            aparato={aparato}
            start={start}
            end={end}
            usuario={usuario}
            getEventos={getEventos}
            setcheck={setcheck}
          />
        ) : (
          ""
        )}

        {addeve ? (
          <AddEvento start={start} end={end} setaddeve={setaddeve} />
        ) : (
          ""
        )}
      </div>
    );
  }
}
