import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBTabsItem,
  MDBTabsContent,
  MDBTabsPane,
  MDBTabs,
  MDBBreadcrumb,
  MDBTabsLink,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

export default function Expediente() {
  const { usuario, paciente, getPaciente, expediente, getExpediente } =
    useContext(UsuarioContext);
  const [diagnosticon, setDiagnosticon] = useState(0);
  const [basicActive, setBasicActive] = useState("");
  const { id } = useParams();

  React.useEffect(() => {
    getPaciente(usuario[0].id, usuario[0].rol, id);
    getExpediente(id);
  }, []);

  const handleBasicClick = (value) => {

    if (value === basicActive) return;

    setBasicActive(value);
  };


  if (paciente.length > 0) {
    return (
      <div className="d-flex justify-content-center pt-5 mt-5 ">
        <section className="col-md-12">
          <MDBContainer className="py-2 border border-3 border-secondary rounded ">
            <MDBRow>
              <MDBCol>
                <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
                  <MDBBreadcrumbItem>
                    <Link to={"/pacientes"}>Regresar</Link>
                  </MDBBreadcrumbItem>
                  <MDBBreadcrumbItem active>
                    Perfil de Usuario
                  </MDBBreadcrumbItem>
                </MDBBreadcrumb>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="3">
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3 bg-primary">
                        <MDBCardText className="fs-6 fw-bold text-white">
                          Fotografia
                        </MDBCardText>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                        <MDBCardImage
                          src="https://st3.depositphotos.com/3776273/31936/i/450/depositphotos_319362956-stock-photo-man-pointing-showing-copy-space.jpg"
                          alt="avatar"
                          className="rounded-circle"
                          style={{ width: "250px" }}
                          fluid
                        />
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md="5">
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3 bg-primary">
                        <MDBCardText className="fs-6 fw-bold text-white">
                          Datos de Paciente
                        </MDBCardText>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                        <MDBCol sm="3" className="fw-bolder">
                          <MDBCardText>Usuario</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" className="fw-semibold">
                          <MDBCardText className="text-muted">
                            {paciente[0].usuario}
                          </MDBCardText>
                        </MDBCol>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                        <MDBCol sm="3" className="fw-bolder">
                          <MDBCardText>Nombre</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" className="fw-semibold">
                          <MDBCardText className="text-muted">
                            {paciente[0].nombre}
                          </MDBCardText>
                        </MDBCol>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                        <MDBCol sm="3" className="fw-bolder">
                          <MDBCardText>Email</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" className="fw-semibold">
                          <MDBCardText className="text-muted">
                            {paciente[0].correo}
                          </MDBCardText>
                        </MDBCol>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                        <MDBCol sm="3" className="fw-bolder">
                          <MDBCardText>Teléfono</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9" className="fw-semibold">
                          <MDBCardText className="text-muted">
                            {paciente[0].telefono}
                          </MDBCardText>
                        </MDBCol>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md="4">
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3 bg-primary">
                        <MDBCardText className="fs-6 fw-bold text-white">
                          Diagnosticos
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBTabs>
                        {expediente.map((elemento) => {
                          return (
                            <MDBListGroupItem
                              action
                            
                              noBorders
                              className="px-3"
                            >
                              <MDBTabsItem>
                                <MDBTabsLink
                                  onClick={() => handleBasicClick(elemento.id)}
                                >
                                  {elemento.diagnostico_corto}
                                </MDBTabsLink>
                              </MDBTabsItem>
                            </MDBListGroupItem>
                          );
                        })}
                      </MDBTabs>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md="6">
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3 bg-primary">
                        <MDBCardText className="fs-6 fw-bold text-white">
                          Detalle Diagnostico
                        </MDBCardText>
                      </MDBListGroupItem>
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBCol size={12}>
                          <MDBTabsContent>
                            {expediente.map((elemento) => {
                              return (
                                <MDBTabsPane open={basicActive === elemento.id}>
                                  {elemento.diagnostico}
                                </MDBTabsPane>
                              );
                            })}
                          </MDBTabsContent>
                        </MDBCol>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md="6">
                <MDBCard className="mb-4 mb-lg-0">
                  <MDBCardBody className="p-0">
                    <MDBListGroup flush className="rounded-3">
                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3 bg-primary">
                        <MDBCardText className="fs-6 fw-bold text-white">
                          Tratamiento
                        </MDBCardText>
                      </MDBListGroupItem>

                      <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                        <MDBCol size={12}>
                          <MDBTabsContent>
                            {expediente.map((elemento) => {
                              return (
                                <MDBTabsPane open={basicActive === elemento.id}>
                                  {elemento.tratamiento}
                                </MDBTabsPane>
                              );
                            })}
                          </MDBTabsContent>
                        </MDBCol>
                      </MDBListGroupItem>
                    </MDBListGroup>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </div>
    );
  } else {
    return <div>cargando</div>;
  }
}
