import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { faUser , faCalendar , faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Nav } from "react-bootstrap";

import UsuarioContext from "../context/usuarios/UsuarioContext";

export default function Menu() {
  const { usuario } = useContext(UsuarioContext);
  return (
    <div>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="warning"
        variant="light"
        border="dark"
        className="border"
      >
        <Navbar.Brand href="#" className="logo">
          {" "}
        </Navbar.Brand>
        <b className="fs-5 fw-bolder ">{usuario[0].nombre}</b>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="d-flex align-content-end justify-content-end">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={({ isActive}) =>
                    isActive ? "active  nav-link fw-bolder fs-6" : "nav-link fs-6 fw-semibold"
                  }
                  to="/"
                >
                   <FontAwesomeIcon icon={faCalendar}/> Agenda
                </NavLink>
              </li>
          
              <li className="nav-item">
                <NavLink
                  className={({ isActive, isPending }) =>
                  isActive ? "active  nav-link fw-bolder fs-6" : "nav-link fs-6 fw-semibold"
                  }
                  to="/pacientes"
                >
                 <FontAwesomeIcon icon={faUser}/> Pacientes
                </NavLink>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
