import { useContext, useState ,useEffect} from "react";
import dayjs from "dayjs";
// import { useUsuario, useUsuarioDispatch } from './UsuarioContext.jsx';
import UsuarioContext from "../context/usuarios/UsuarioContext.js";
import { faRefresh,faCalendarCheck,faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddEvento({start,end,setaddeve}) {

  const {setEvento,getExpediente,expediente,usuario,getEventos,getPacientes,pacientes,getSesiones,sesiones } = useContext(UsuarioContext);

  const [paciente, setpaciente] = useState(0);
  const [terapia, setterapia] = useState("");
  const [aparato, setaparato] = useState("");
  useEffect(() => {
    setpaciente(0)
   getPacientes(usuario[0].id,usuario[0].rol)
      },[])

  useEffect(() => {
getExpediente(paciente)
getSesiones(paciente)
  },[paciente])


  console.log(expediente)
  return (

  
    <div className=" d-flex justify-content-center align-content-center p-2 m-0 ">
      <div className=" col-10 ">
        <div className="card border rounded col-12 col-sm-12 col-md-12 col-lg-12 p-1 m-1">
          <div className="card-header">
            <label className="fs-5 fw-bold">Agendar Cita</label>
          </div>

          <div className="row col-12 card-body m-0 p-0 ">
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4 m-0 p-0  ">
               <label className="fs-6 fw-bold p-1">Paciente</label>
                <select
                  name="paciente"
                  id="paciente"
                  className="form-control fs-6 fw-medium"
                  value={paciente}
                  onChange={(e) => {
                    setpaciente(e.target.value);
                  }}
                >
                  {" "}
                  <option value="0" >
                    Selecciona Paciente
                  </option>
                  {pacientes.map((elemento) => {
                    return (
                      <option value={elemento.id}>
                        {elemento.nombre}
                      </option>
                    );
                  })}
                </select>
              
            </div>
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4  m-0 p-0">
              
                <label className="fs-6 fw-bold p-1">Terapía</label>
                <select
                  name="terapia"
                  id="terapia"
                  className="form-control fs-6 fw-medium"
                  onChange={(e) => {
                    setterapia(e.target.value);
                    console.log(e.target.value);
                  }}
                ><option value ='0' >Selecciona terapia</option>
                    {sesiones.map((elemento) => {
                    return (
                      <option value={elemento.id_terapia}>
                        {elemento.terapia}
                      </option>
                    );
                  })}
                </select>
              
            </div>
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4 m-0 p-0 ">
              
                <label className="fs-6 fw-bold p-1">Aparato</label>
                <select
                  name="aparato"
                  id="aparato"
                  className="form-control fs-6 fw-medium"
                  onChange={(e) => {
                    setaparato(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  {" "}
                  <option value="0" >
                    Selecciona Aparato
                  </option>
                  {sesiones.map((elemento) => {
                    return (
                      <option value={elemento.aparatos}>
                        {elemento.aparatos}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="row col-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0  ">
                <label className="fs-6 fw-bold">Fecha de Cita</label>
                <input type="date" step ='60' className='form-control' name="fecha" id="fecha" value={dayjs(start).format('YYYY-MM-DD')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  p-0 m-0">
                <label className="fs-6 fw-bold">Hora Inicia</label>
                <input type="time"  step ='60'  className='form-control'  name="inicio" id="inicio" value={dayjs(start).format('HH:mm:ss')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  p-0 m-0">
                <label className="fs-6 fw-bold">Hora Termina</label>
                <input type="time"  step ='60'  className='form-control'  name="fin" id="fin" value={dayjs(end).format('HH:mm:ss')}  readOnly/>
              </div>

              <div className="col-12 d-flex justify-content-end align-content-end  p-0 m-0 mt-2">
                <button
                  className="btn btn-primary btn-block fs-6 fw-bold"
                  onClick={() => {setEvento({paciente,terapia,aparato,start:dayjs(start).format('YYYY-MM-DD HH:mm:ss'),end:dayjs(end).format('YYYY-MM-DD HH:mm:ss')});setpaciente(0);getPacientes(usuario[0].id,usuario[0].rol)}}
                >
                     <FontAwesomeIcon icon={faCalendarCheck}   /> Agendar Nueva Cita
                </button>
                <button
                  className="btn btn-warning btn-block fs-6 fw-bold"
                  onClick={() => {getEventos(usuario[0].id,usuario[0].rol)}}
                >
                 <FontAwesomeIcon icon={faRefresh}  /> Actualizar
                </button>
                <button
                  className="btn btn-danger btn-block fs-6 fw-bold"
                  onClick={() => {setaddeve(false)}}
                >
                 <FontAwesomeIcon icon={faRightFromBracket}   /> Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
   



  );
}
