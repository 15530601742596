import { useState, useEffect, useContext } from "react";
import Login from "./pages/login.jsx";
import UsuarioContext from "./context/usuarios/UsuarioContext.js";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRouter from "./routes/AppRouter.jsx";
import LoginRouter from "./routes/LoginRouter.jsx";

export default function App() {
  const [username, setUsername] = useState("");
  const { getUsuario, usuario, setUsuario } = useContext(UsuarioContext);

  useEffect(() => {
   
const user =  JSON.parse(localStorage.getItem("log"))

    if (user) {
      if (user.length > 0) {
      setUsuario(user);
      
    } else {
      localStorage.setItem("log", JSON.stringify(usuario));
    }
  }
  }, []);

  useEffect(() => {
    if (usuario.length > 0) {
      localStorage.setItem("log", JSON.stringify(usuario));
      setUsername(usuario[0].nombre);
    } 
  }, [ usuario]);

  if (username === "") {
    return (
      <div>
        <Login />
      </div>
    );
  } else {
    return (
      <div>
        <AppRouter />
      </div>
    );
  }
}
