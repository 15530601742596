
import dayjs from "dayjs";

import { faRefresh,faCalendarXmark,faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function CheckEvento({start,end,paciente,terapia,aparato,usuario,getEventos,setcheck}) {

  return (

  
    <div className=" d-flex justify-content-center align-content-center p-2 m-0 ">
      <div className=" col-9 ">
        <div className="card border rounded col-12 col-sm-12 col-md-12 col-lg-12 p-1 m-1">
          <div className="card-header">
            <label className="fs-5 fw-bold">Información de la Cita</label>
          </div>

          <div className="row col-12 card-body m-0 p-0 ">
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4 m-0 p-0  ">
               <label className="fs-6 fw-bold p-1">Paciente</label>
                 <input type="text" name='paciente' id='paciente' value={paciente}  readOnly/>
             
            </div>
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4  m-0 p-0">
              
                <label className="fs-6 fw-bold p-1">Terapía</label>
                <input type="text" name='terapia' id='terapia' value={terapia}  readOnly/>             
            </div>
            <div className="row col-12 col-sm-12 col-md-4 col-lg-4 m-0 p-0 ">
              
                <label className="fs-6 fw-bold p-1">Aparato</label>
                <input type="text" name='aparato' id='aparato' value={aparato}  readOnly/>
              </div>

              <div className="row col-12 col-sm-12 col-md-4 col-lg-4 p-0 m-0  ">
                <label className="fs-6 fw-bold">Fecha de Cita</label>
                <input type="date" step ='60' className='form-control' name="fecha" id="fecha" value={dayjs(start).format('YYYY-MM-DD')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  p-0 m-0">
                <label className="fs-6 fw-bold">Hora Inicia</label>
                <input type="time"  step ='60'  className='form-control'  name="inicio" id="inicio" value={dayjs(start).format('HH:mm:ss')}  readOnly/>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4  p-0 m-0">
                <label className="fs-6 fw-bold">Hora Termina</label>
                <input type="time"  step ='60'  className='form-control'  name="fin" id="fin" value={dayjs(end).format('HH:mm:ss')}  readOnly/>
              </div>

              <div className="col-12 d-flex justify-content-end align-content-end  p-0 m-0 mt-2">
                  <button
                  className="btn btn-warning btn-block fs-6 fw-bold"
                  onClick={() => {getEventos(usuario[0].id,usuario[0].rol)}}
                >
                  <FontAwesomeIcon icon={faRefresh}  /> Actualizar
                </button>
                <button
                  className="btn btn-danger btn-block fs-6 fw-bold"
                  onClick={() => {}}
                >
                 <FontAwesomeIcon icon={faCalendarXmark}  /> Cancelar Cita
                </button>
                <button
                  className="btn btn-primary btn-block fs-6 fw-bold"
                  onClick={() => {setcheck(false)}}
                >
                 <FontAwesomeIcon icon={faRightFromBracket}  /> cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
   



  );
}
