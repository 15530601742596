import {
  GET_USUARIO,
  GET_EVENTOS,
  SET_EVENTO,
  GET_EXPEDIENTE,
  GET_PACIENTES,
  GET_SESIONES,
  GET_PACIENTE,
  SET_USUARIO
} from "../types";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const { payload, type } = action;

  switch (type) {

    case SET_USUARIO:
      return {
        ...state,
        usuario: payload,
      };
    case GET_USUARIO:
      return {
        ...state,
        usuario: payload,
      };
    case GET_EVENTOS:
      return {
        ...state,
        eventos: payload,
      };
    case GET_EXPEDIENTE:
      return {
        ...state,
        expediente: payload,
      };
      case GET_SESIONES:
        return {
          ...state,
          sesiones: payload,
        };
    case GET_PACIENTES:
      return {
        ...state,
        pacientes: payload,
      };
      case GET_PACIENTE:
        return {
          ...state,
          paciente: payload,
        };
    case SET_EVENTO:
      return {
        ...state,
        resp: payload,
      };
    default:
      return {
        state,
      };
  }
};
